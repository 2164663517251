import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { CAMERA_MANAGER } from '../components/camera-manager';

function EditCameraModal({ id, onClose }) {
    const { CAMERA_INFO_FETCH, CAMERA_UPDATE } = CAMERA_MANAGER();
    const [show, setShow] = useState(true);
    const [loader, setLoader] = useState("Save Changes");
    const [state, setState] = useState({
        id: '',
        camera_name: '',
        rtsp_url: '',
        location: '',
        camera_type: '',
    });

    //Fetch the camera details
    useEffect(() => {
        CAMERA_INFO_FETCH(id).then(cameraDetails => {
            setState(cameraDetails);
        });
    }, [id]);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const handleUpdate = async(event) => {
        event.preventDefault();
        setLoader("Saving...");
        await CAMERA_UPDATE(state.id, 
                            state.camera_name, 
                            state.rtsp_url,
                            state.location,
                            state.camera_type);
        setLoader("Save Changes");
        onClose();
        setShow(false);
    }

    return (
        <Modal show={ show } onHide={ handleClose } centered>
            <Modal.Header>
                <Modal.Title className="text-white fw-bold">Edit Camera</Modal.Title>
            </Modal.Header>

            <form onSubmit={ handleUpdate }>
                <Modal.Body>
                    <div className="mb-4">
                        <label className="edit-modal-label">Camera Name :</label>
                            <input
                                type="text"
                                name="camera_name"
                                className="border-0 mt-3 rounded-3 edit-modal-textbox p-3"
                                value={state.camera_name}
                                onChange={handleChange}
                                placeholder="Camera Name"
                                required
                            />
                    </div>

                    <div className="mb-4">
                        <label className="edit-modal-label">Location :</label>
                            <input
                                type="text"
                                name="location"
                                className="border-0 mt-3 rounded-3 edit-modal-textbox p-3"
                                value={state.location}
                                onChange={handleChange}
                                placeholder="Location"
                                required
                            />
                    </div>

                    <div className="mb-4">
                        <label className="edit-modal-label">Camera Type :</label>
                            <select name="camera_type"
                                    className="border-0 mt-3 rounded-3 edit-modal-textbox p-3" 
                                    value={state.camera_type} 
                                    onChange={ handleChange } 
                                    required>
                                <option value="" defaultValue disabled>Select Type</option>
                                <option value="Entrance/Exit">Entrance/Exit</option>
                                <option value="Parking Lot">Parking Lot</option>
                            </select>
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="info" onClick={ handleClose }>
                        Close
                    </Button>
                    <Button variant="success" type="submit" disabled = {loader === "Saving..."}>
                        {loader}
                    </Button>
                </Modal.Footer>
            </form>

        </Modal>
    )
}

export default EditCameraModal;