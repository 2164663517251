import { ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
import { VEHICLE_MANAGER } from "../components/vehicle-manager";
import { TOAST_FAIL } from "../components/toasters";

function RegisterVehiclePage() {
    const [loader, setLoader] = useState("Register");
    const { VEHICLE_REGISTER_1 } = VEHICLE_MANAGER();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [docPreviewUrl, setDocPreviewUrl] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [step, setStep] = useState(1);

    const [state, setState] = useState({
        plate_number: '',
        username: '',
        valid_id_url: 'null',
        license_doc_url: 'null',
        identity: '',
        email: '',
        contact_number: '',
        purpose: '',
    });

    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if(file && file.type.startsWith('image/')) {
            setState((prevState) => ({
                ...prevState,
                valid_id_url: file,
            }));
            setImagePreviewUrl(URL.createObjectURL(file));
        } else {
            TOAST_FAIL("Please select a valid image file");
            setImagePreviewUrl(null);
            setState((prevState) => ({ ...prevState, valid_id_url: 'null' }));
        }
    };

    const handleDocumentChange = (event) => {
        const file = event.target.files[0];
        if(file && file.type.startsWith('image/')) {
            setState((prevState) => ({
                ...prevState,
                license_doc_url: file,
            }));
            setDocPreviewUrl(URL.createObjectURL(file));
        } else {
            TOAST_FAIL("Please select a valid image file");
            setState((prevState) => ({ ...prevState, license_doc_url: 'null' }));
        }
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        setLoader("Registering...");
    
        const formData = new FormData();
        Object.keys(state).forEach(key => formData.append(key, state[key]));
    
        await VEHICLE_REGISTER_1(formData);
    
        setState({
            plate_number: '',
            username: '',
            email: '',
            contact_number: '',
            identity: '',
            purpose: null,
            valid_id_url: null,
            license_doc_url: null,
        });
        setImagePreviewUrl(null);
        setDocPreviewUrl(null);
        setLoader("Register");
    };

    const nextStep = () => {
        if (!state.plate_number || 
            !state.username || 
            !state.contact_number ||
            !state.email ||
            !state.identity ) {
            TOAST_FAIL("Please fill out all the fields before proceeding to next step");
        } else {
            setStep((prev) => prev + 1);
        }
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const prevStep = () => setStep((prev) => prev - 1);

    return (
        <div className="loginBackground d-flex justify-content-center align-items-center">
            <ToastContainer />

            <form onSubmit={ handleSubmit } 
                  className={`d-flex row ${step === 1 ? "register-vehicle-container" : "register-vehicle-container-2"} 
                             justify-content-center bg-white mb-5 p-5 rounded-3`}>
                <h1 className="text-center fw-semibold mb-4">Register Vehicle</h1>
                <p className="text-center register-p">Register to gain access in the University</p>
                <hr />

                {step === 1 && (
                    <div className="step d-flex row">
                        <p className="step-p mb-4 text-center">Step {step} out of 2: Please fill out the following fields</p>
                        <input type="text"
                               name="plate_number"
                               className="register-vehicle-textbox mt-2"
                               placeholder="Plate Number"
                               maxLength={8}
                               value={state.plate_number}
                               onChange={handleChange}
                               required />
                        
                        <input type="text"
                               name="username"
                               className="register-vehicle-textbox mt-3"
                               maxLength={16}
                               placeholder="Owner Name"
                               value={state.username}
                               onChange={handleChange}
                               required />

                        <input type="number"
                               name="contact_number"
                               className="register-vehicle-textbox mt-3"
                               placeholder="Contact Number"
                               maxLength={11}
                               value={state.contact_number}
                               onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                               onChange={handleChange}
                               required />
                        
                        <input type="email"
                               name="email"
                               className="register-vehicle-textbox mt-3"
                               placeholder="Email"
                               value={state.email}
                               onChange={handleChange}
                               required />

                        <select name="identity"
                                className="register-vehicle-textbox mt-3"
                                value={state.identity}
                                onChange={handleChange}
                                required>
                            <option value="" disabled>Select your Identity</option>
                            <option value="Employee">Employee</option>
                            <option value="Visitor">Visitor</option>
                        </select>

                        {state.identity === "Visitor" && 
                        <select name="purpose"
                                className="register-vehicle-textbox mt-3"
                                value={state.purpose}
                                onChange={handleChange}
                                required>
                            <option value="" disabled>Select your purpose</option>
                            <option value="Official Business">Official Business</option>
                            <option value="Guest Lecture">Guest Lecture</option>
                            <option value="Job Interview">Job Interview</option>
                            <option value="Student Affairs">Student Affairs</option>
                            <option value="Research Collaboration">Research Collaboration</option>
                            <option value="Facility Maintenance">Facility Maintenance</option>
                            <option value="Conference/Seminar">Conference/Seminar</option>
                            <option value="Alumni Visit">Alumni Visit</option>
                            <option value="Event Participation">Event Participation</option>
                            <option value="Campus Tour">Campus Tour</option>
                            <option value="Vendor Delivery">Vendor Delivery</option>
                            <option value="Personal Visit">Personal Visit</option>
                            <option value="Library Access">Library Access</option>
                            <option value="Meeting with Faculty">Meeting with Faculty</option>
                            <option value="Parent/Guardian Visit">Parent/Guardian Visit</option>
                            <option value="Student Counseling">Student Counseling</option>
                            <option value="Health Services">Health Services</option>
                            <option value="Financial Aid Inquiry">Financial Aid Inquiry</option>
                            <option value="Security Clearance">Security Clearance</option>
                            <option value="Project Presentation">Project Presentation</option>
                            <option value="Cultural/Arts Visit">Cultural/Arts Visit</option>
                            <option value="Sports Event Participation">Sports Event Participation</option>
                            <option value="Admissions Inquiry">Admissions Inquiry</option>
                            <option value="Dormitory Visit">Dormitory Visit</option>
                            <option value="IT Support">IT Support</option>
                            <option value="Student Registration">Student Registration</option>
                            <option value="Career Fair">Career Fair</option>
                            <option value="Volunteer Activity">Volunteer Activity</option>
                            <option value="Public Lecture">Public Lecture</option>
                        </select>
                    }

                        <div className="d-flex column justify-content-center">
                            <button type="button"
                                    className="register-vehicle-button text-white mt-5 rounded-2" 
                                    onClick={nextStep}>Next</button>
                        </div>

                    </div>
                )}

                {step === 2 && (
                    <div className="step d-flex row">
                        <p className="step-p mb-4 text-center">Step {step} out of 2: Please upload the following needed images</p>
                        <div className="step d-flex row justify-content-center">

                            <div className="d-flex row">
                                <div className="column">
                                    <label className="mt-3">Choose Valid ID image :</label>
                                    <input type="file"
                                           name="valid_id_url"
                                           className="register-vehicle-file form-control w-100"
                                           onChange={handleImageChange}
                                           accept="image/*"
                                           required />
                                </div>
                                <div className="column">
                                    <label className="mt-3">Official Receipt/Certificate of Registration :</label>
                                    <input type="file"
                                           name="license_doc_url"
                                           className="register-vehicle-file form-control w-100"
                                           onChange={handleDocumentChange}
                                           accept="image/*"
                                           required />
                                </div>
                            </div>
                            
                            <div className="d-flex column justify-content-evenly w-100">
                                {!isMobile ? (
                                    <div>
                                        {docPreviewUrl ? (
                                            <img 
                                                src={ docPreviewUrl }
                                                alt="Cor/Official Reciept"
                                                className="rounded text-center image-preview w-100"
                                        />
                                        ) : (
                                            <div className="text-muted image-preview d-flex justify-content-center align-items-center">
                                                <p>No image selected</p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                {!isMobile ? (
                                    <div>
                                        {imagePreviewUrl ? (
                                            <img 
                                                src={ imagePreviewUrl }
                                                alt="Valid ID"
                                                className="rounded text-center image-preview w-100"
                                        />
                                        ) : (
                                            <div className="text-muted image-preview d-flex justify-content-center align-items-center">
                                                <p>No image selected</p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>

                        </div>

                        <div className="d-flex column justify-content-evenly">
                            <button type="button" className="register-vehicle-button mt-5 text-white rounded-2" 
                                    onClick={prevStep}>Previous</button>
                            <button type="submit" className="register-vehicle-button mt-5 text-white rounded-2" 
                                    disabled={loader === "Registering..."}>{loader}</button>
                        </div>
                        
                    </div>
                )}
            </form>
        </div>
    );
}

export default RegisterVehiclePage;