import { ToastContainer } from "react-toastify";
import Sidenav from "../templates/sidenav-template";
import React, { useEffect, useState, useRef } from 'react';
import { CAMERA_MANAGER } from "../components/camera-manager";
import Loader from "../templates/loader-template";
import alarm from "../audios/alarm.wav";
import { TOAST_FAIL } from "../components/toasters";

function HomePage() {
    const [cameras, setCameras] = useState([]);
    const alarmAudioRef = useRef(null);
    const [loader, setLoader] = useState(false);
    const { CAMERA_FETCH } = CAMERA_MANAGER();
    const ip = "https://flask-api.lnu-vms.online";

    useEffect(() => {
        setLoader(true);
        const fetchCameras = async () => {
            const data = await CAMERA_FETCH();
            setCameras(data);
            setLoader(false);
        };
        fetchCameras();
    }, []);

    const fetchAlarmMessage = async () => {
        const response = await fetch(`${ ip }/send-alarm`);
        const data = await response.json();

        if (data.message === "Alarm") {
            // Play the alarm sound if the message is "Alarm"
            TOAST_FAIL(`A vehicle has exceeded the time allowed to remain/park inside the campus at ${ data.location }`);

            if (alarmAudioRef.current) {
                alarmAudioRef.current.play();
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            fetchAlarmMessage();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="d-flex home-page">
            <ToastContainer />
            <div className="sidenav-position">
                <Sidenav />
            </div>
            <audio ref={ alarmAudioRef } src={ alarm } preload="auto"/>
            
            <div className="feed-container">
                {loader? (
                    <Loader text={ "Fetching All Cameras" }/>
                ) : cameras.length === 0 ? (
                    <p className="no-camera-message text-center mt-5">No cameras found</p>
                ) : (
                    <div className="video-container column mt-2 gap-2">
                        {cameras.map(camera => (
                            <div key={camera.id} className="video-feed-container rounded-1">
                                <p className="ps-3">{camera.camera_type} ({camera.location})</p>
                                <img
                                    alt="video-feed"
                                    className="video-feed rounded-1"
                                    src={`${ ip }/video_feed/${ camera.id }`}
                                ></img>
                            </div>
                        ))}
                    </div>
                )}
            </div>

        </div>
    )
}

export default HomePage;