import Sidenav from "../templates/sidenav-template";
import { ToastContainer } from "react-toastify";
import PendingList from "../data-tables/pending-table";
import { useState } from "react";

function PendingPage() {
    const [pendingCount, setPendingCount] = useState(0); 

    return (
        <div className="d-flex pending-page">
            <ToastContainer />
            <Sidenav />
            <PendingList />
        </div>
    )
}
export default PendingPage;