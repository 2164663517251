import { CAMERA_MANAGER } from '../components/camera-manager';
import { useState } from 'react';

function CameraRegisterForm() {
    const { CAMERA_REGISTER } = CAMERA_MANAGER();
    const [loader, setLoader] = useState("Register");
    const [state, setState] = useState({
        camera_name: '',
        rtsp_url: '',
        location: '',
        camera_type: '',
    });

    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const handleRegister = async(event) => {
        event.preventDefault();
        setLoader("Registering...");
        await CAMERA_REGISTER(state.camera_name, 
                              state.rtsp_url, 
                              state.location, 
                              state.camera_type);
        setState((prevState) => ({
            ...prevState,
            camera_name: '',
            rtsp_url: '',
            location: '',
            camera_type: '',
        }));
        setLoader("Register");
    }

    return (
        <div>
            <form onSubmit={ handleRegister } className="camera-form-container rounded-3 p-4 text-center">
            <h3 className="text-center text-white mb-4 fw-bold">Camera Register</h3>
            <hr  className="text-white border-2"/>
                <input
                    type="text"
                    name="camera_name"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    value={state.camera_name}
                    onChange={handleChange}
                    placeholder="Camera Name"
                    required
                />
                <br />
                <input
                    type="text"
                    name="rtsp_url"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    value={state.rtsp_url}
                    onChange={handleChange}
                    placeholder="RTSP Url"
                    required
                />
                <br />
                <input
                    type="text"
                    name="location"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    value={state.location}
                    onChange={handleChange}
                    placeholder="Location"
                    required
                />
                <br />
                <select name="camera_type" value={state.camera_type} 
                        onChange={handleChange} 
                        className="register-textbox border-0 mt-3 rounded-1 ps-3"
                        required>
                    <option value="" defaultValue disabled>Select Camera Type</option>
                    <option value="Entrance/Exit">Entrance/Exit</option>
                    <option value="Parking Lot">Parking Lot</option>
                </select>
                <br />
                <hr/>
                <button type="submit" 
                        className="register-button border-0 mt-2 w-75 rounded-3 fw-bold"
                        disabled={loader === "Registering..."}>{ loader }</button>
            </form>
        </div>
    )
}

export default CameraRegisterForm;