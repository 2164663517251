import { Modal, Button } from 'react-bootstrap';
import { VEHICLE_MANAGER } from '../components/vehicle-manager';
import { useState, useEffect } from "react";
import { LARAVEL_URL } from '../backend-api/api';

function ViewPendingModal({ plate_number, onClose }) {
    const { FETCH_PENDING_DETAILS, ACCEPT_REQUEST, DENY_REQUEST } = VEHICLE_MANAGER();
    const [show, setShow] = useState(true);
    const [loader1, setLoader1] = useState("Accept");
    const [loader2, setLoader2] = useState("Deny");
    const [state, setState] = useState({
        plate_number: '',
        username: '',
        valid_id_url: 'null',
        license_doc_url: 'null',
        email: '',
        contact_number: '',
        identity: '',
        purpose: '',
    });

    //Fetch the pending details
    useEffect(() => {
        FETCH_PENDING_DETAILS(plate_number).then(pendingDetails => {
            setState(pendingDetails);
        });
    }, [plate_number]);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleDeny = async(event) => {
        event.preventDefault();
        setLoader2("Denying...");
        await DENY_REQUEST(state.plate_number);
        setLoader2("Deny");
        onClose();
        setShow(false);
    }

    const handleAccept = async(event) => {
        event.preventDefault();
        setLoader1("Accepting...");
        await ACCEPT_REQUEST(state.plate_number, state.email, state.username);
        setLoader1("Accept");
        onClose();
        setShow(false);
    }

    return (
        <Modal  show={ show } onHide={ handleClose } size='xl' centered>
            <Modal.Header>
                <Modal.Title className="text-white fw-bold">Request Detail</Modal.Title>
            </Modal.Header>

            <Modal.Body className="d-flex column pending-modal">
                <div className="d-flex align-items-center pending-modal-content row mb-0">
                    <div className="d-flex detail">
                        <label className="fw-bold edit-modal-label">Plate Number:</label>
                        <p className="">{ state.plate_number }</p>
                    </div>
                    <div className="d-flex detail">
                        <label className="fw-bold edit-modal-label">Owner Name:</label>
                        <p className="">{ state.username }</p>
                    </div>
                    <div className="d-flex detail">
                        <label className="fw-bold edit-modal-label">Email:</label>
                        <p className="">{ state.email }</p>
                    </div>
                    <div className="d-flex detail">
                        <label className="fw-bold edit-modal-label">Contact Number :</label>
                        <p className="">{ state.contact_number }</p>
                    </div>
                    <div className="d-flex detail">
                        <label className="fw-bold edit-modal-label">Purpose :</label>
                        <p className="">{ state.purpose }</p>
                    </div>
                    <div className="d-flex detail">
                        <label className="fw-bold edit-modal-label">Official Reciept/COR :</label>
                        <a className="" href={ `${ LARAVEL_URL }/storage/${ state.license_doc_url }` } target='_blank'>
                            View image
                        </a>
                    </div>
                </div>
                <div className="">
                    <img 
                        src={ `${ LARAVEL_URL }/storage/${ state.valid_id_url }` }
                        className="rounded text-center image-preview mt-2 ms-5"/>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="info" onClick={ handleClose }>
                    Close
                </Button>
                <Button variant="danger" onClick={ handleDeny } disabled = {loader2 === "Denying..." || loader1 === "Accepting..."}>
                    {loader2}
                </Button>
                <Button variant="success" onClick={ handleAccept } disabled = {loader1 === "Accepting..." || loader2 === "Denying..."}>
                    {loader1}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewPendingModal;